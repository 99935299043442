<style scoped>
.no-uppercase {
  text-transform: none;
}
.selected {
  background-color: #477B7E !important;
  color: white;
}

.correct {
  background-color: #4caf50 !important;
  opacity: 0.8;
  color: white;
}

.wrong {
  background-color: #477B7E !important;
  color: white;
}

.lowerPadding {
  /* if we animate v-card-actions with v-expand-transition we animation is laggy and with padding enabled it looks unbalanced  */
  padding-top: 0;
  padding-bottom: 0;
}

.icon {
  color: #477B7E !important;
}

.iconDisabled {
  color: rgba(0, 0, 0, 0.12) !important;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.12);
}

.removePadding {
  padding-right: 0px !important;
}

.answer {
  width: 100%;
}
</style>
<template>
  <v-card outlined :class="{ disabled: disabled }">
    <v-card-text>
      <span v-html="questionAnswers.question"></span>
      <v-btn icon @click="toggleShowAnswersLocal">
        <v-icon class="icon" :class="{ iconDisabled: disabled }">{{
          showAnswersLocal ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </v-card-text>

    <v-card-actions class="lowerPadding">
      <v-expand-transition>
        <v-row v-show="showAnswersLocal" style="width: 100%;">
          <!-- ToDo: What are valid layouts? two in a row, four in a row, ...? controllable with examples -->
          <v-col
            v-for="(answer, answer_index) in questionAnswers.answers"
            :key="answer_index"
            :ref="answer_index"
            :cols="answer.cols ? answer.cols : 12"
          >
            <v-card
              @click="selectAnswer(answer, answer_index)"
              :class="{
                selected: selectedAnswer == answer,
                correct:
                  showAnswersEvaluated &&
                  selectedAnswer == answer &&
                  answer.correct,
                wrong:
                  showAnswersEvaluated &&
                  selectedAnswer == answer &&
                  !answer.correct,
              }"
              :disabled="disabled"
            >
              <v-card-text style="color: inherit;" v-html="answer.text">
              </v-card-text>
              <!-- ATTENTION: The +1 is to avoid 0 based index in the logging -->
              <AppExpandableImage
                v-if="answer.image"
                :src="answer.image"
                contain
                :parentId="parentId"
                :elementId="
                  'Ebene: ' +
                    (1 + stage) +
                    ' Frage: ' +
                    (1 + questionIndex) +
                    ' Antwort: ' +
                    (1 + answer_index)
                "
              />
            </v-card>
          </v-col>
          <!-- response box -->
          <v-col v-if="showResponseLocal" cols="12">
            <AppAlertTooltip
              :textHtml="selectedAnswer.response"
              :additionalInformationHtml="
                selectedAnswer.additionalExplanationResponse
              "
              :type="selectedAnswer.correct ? 'success' : 'info'"
              :parentId="parentId"
            />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppExpandableImage from "@/common/AppExpandableImage";
// import LernbausteinStempelnElementAdditionalInfo from "./LernbausteinStempelnElementAdditionalInfo.vue";
import AppHelper from "@/common/AppHelper";

export default {
  components: {
    AppAlertTooltip,
    AppExpandableImage,
    // LernbausteinStempelnElementAdditionalInfo,
  },
  props: {
    questionAnswers: {
      type: Object,
      required: true,
    },
    stage: { type: Number, required: true },
    disabled: { type: Boolean, required: false },
    showAnswers: { type: Boolean, default: false },
    parentId: { String, required: true },
    questionIndex: { String, required: true },
  },
  watch: {
    showAnswers: function(val) {
      this.showAnswersLocal = val;
    },
  },
  data: function() {
    return {
      selectedAnswer: {},
      selectedAnswerIndex: -1,
      showResponseLocal: false,
      answerSelectedEmitted: false,
      showAnswersEvaluated: false,
      showAnswersLocal: this.showAnswers,
    };
  },

  methods: {
    restoreState: function(restoredState) {
      this.selectedAnswerIndex = restoredState.selectedAnswerIndex;
      // the selectAnswer method changes the behaviour regarding if the feedback should be displayed, so we have to set it later than the select answer call
      this.showResponseLocal = restoredState.showResponseLocal;
      this.showAnswersEvaluated = restoredState.showAnswersEvaluated;
      this.showAnswersLocal = restoredState.showAnswersLocal;

      if (this.selectedAnswerIndex != -1) {
        // if we have an answer selected
        // this.answerSelectedEmitted = restoredState.answerSelectedEmitted; // Don't Do that! We have to act like this was the first time we selected the answer so the selectAnswer method works as intended
        this.selectedAnswer = this.questionAnswers.answers[
          restoredState.selectedAnswerIndex
        ];
        // this.selectAnswer(this.selectedAnswer, this.selectedAnswerIndex);
        let restoreState = true;
        this.$emit("answerSelected", this._self, restoreState);
        this.answerSelectedEmitted = true;
      }
    },
    selectAnswer: function(selectedAnswer, answerIndex) {
      this.selectedAnswer = selectedAnswer;
      this.selectedAnswerIndex = answerIndex;
      this.showResponseLocal = false;
      this.showAnswersEvaluated = false;

      if (!this.answerSelectedEmitted) {
        /* only send one event per quesion on a stage */
        this.$emit("answerSelected", this._self);
      } else {
        this.$emit("answerChanged", this._self);
      }
      this.answerSelectedEmitted = true;

      AppHelper.trackMatomoEvent(
        this,
        "LBSTStempeln",
        "id:39; Ebene: " +
          (1 + this.stage) +
          " Frage: " +
          (1 + this.questionIndex) +
          " Antwort: " +
          (1 + answerIndex) +
          " ausgewählt;",
        this.parentId
      );
    },

    checkAndShowResponse: function(trackMatomoEvents) {
      this.showResponseLocal = true;
      this.showAnswersLocal = true;
      this.showAnswersEvaluated = true;

      if (trackMatomoEvents) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTStempeln",
          "id:48; Ebene: " +
            (1 + this.stage) +
            " Frage: " +
            (1 + this.questionIndex) +
            " korrekt gelöst: " +
            this.selectedAnswer.correct +
            ";",
          this.parentId
        );
      }

      return this.selectedAnswer;
    },

    hideResponse: function() {
      this.showResponseLocal = false;
    },

    reset: function() {
      this.selectedAnswer = {};
      this.showResponseLocal = false;
      this.answerSelectedEmitted = false;
      this.showAnswersLocal = this.showAnswers;
      this.selectedAnswerIndex = -1;
    },

    toggleShowAnswersLocal: function() {
      this.showAnswersLocal = !this.showAnswersLocal;
      AppHelper.trackMatomoEvent(
        this,
        "LBSTStempeln",
        "id:47; Ebene: " +
          (1 + this.stage) +
          " deaktiviert: " +
          this.disabled +
          " Frage: " +
          (1 + this.questionIndex) +
          " Aufklappzustand von: " +
          !this.showAnswersLocal +
          " zu: " +
          this.showAnswersLocal +
          ";",
        this.parentId
      );
    },
  },
};
</script>
