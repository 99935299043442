var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{ disabled: _vm.disabled },attrs:{"outlined":""}},[_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionAnswers.question)}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleShowAnswersLocal}},[_c('v-icon',{staticClass:"icon",class:{ iconDisabled: _vm.disabled }},[_vm._v(_vm._s(_vm.showAnswersLocal ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-card-actions',{staticClass:"lowerPadding"},[_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswersLocal),expression:"showAnswersLocal"}],staticStyle:{"width":"100%"}},[_vm._l((_vm.questionAnswers.answers),function(answer,answer_index){return _c('v-col',{key:answer_index,ref:answer_index,refInFor:true,attrs:{"cols":answer.cols ? answer.cols : 12}},[_c('v-card',{class:{
              selected: _vm.selectedAnswer == answer,
              correct:
                _vm.showAnswersEvaluated &&
                _vm.selectedAnswer == answer &&
                answer.correct,
              wrong:
                _vm.showAnswersEvaluated &&
                _vm.selectedAnswer == answer &&
                !answer.correct,
            },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.selectAnswer(answer, answer_index)}}},[_c('v-card-text',{staticStyle:{"color":"inherit"},domProps:{"innerHTML":_vm._s(answer.text)}}),(answer.image)?_c('AppExpandableImage',{attrs:{"src":answer.image,"contain":"","parentId":_vm.parentId,"elementId":'Ebene: ' +
                  (1 + _vm.stage) +
                  ' Frage: ' +
                  (1 + _vm.questionIndex) +
                  ' Antwort: ' +
                  (1 + answer_index)}}):_vm._e()],1)],1)}),(_vm.showResponseLocal)?_c('v-col',{attrs:{"cols":"12"}},[_c('AppAlertTooltip',{attrs:{"textHtml":_vm.selectedAnswer.response,"additionalInformationHtml":_vm.selectedAnswer.additionalExplanationResponse,"type":_vm.selectedAnswer.correct ? 'success' : 'info',"parentId":_vm.parentId}})],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }